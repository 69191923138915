$fire-engine-red: red;

.section-css {
   &.desktop-section-css {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      align-items: center;
   }
   &.mobile-section-css {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
   }
   padding: 20px;
   .sch-donation-css {
      width: auto;
      height: auto;
   }
   .make-appointment-button {
      width: 250px;
      font-size: 12px;
      background-color: $fire-engine-red;
      border-color: $fire-engine-red;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
         0 4px 8px 3px rgba(60, 64, 67, 0.15);
   }
}
