@import "src/app/style/appStyle";

// Logo
$logo-wrapper-height: $header-height;
$logo-wrapper-width: $navbar-expanded-width;
$logo-width: auto;
$logo-height: $header-height * 0.6;
$transitionDuration: 0.2s;

#layout-header {
   height: $header-height;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 998;
   width: 100%;
   transition: width $transitionDuration;
   display: flex;

   .active {
      .logo-wrapper {
         .arrow-button {
            > i {
               transform: rotate(180deg);
            }
         }
      }
   }

   #TopNavbar {
      height: $header-height;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      z-index: 10;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.28);

      .logo-wrapper {
         width: $logo-wrapper-width;
         min-width: $logo-wrapper-width;
         height: $logo-wrapper-height;
         background-color: white;
         display: flex;
         padding-right: 1.25rem;
         display: flex;
         align-items: center;
         justify-content: center;

         img {
            width: $logo-width;
            height: $logo-height;
         }
      }

      .arrow-button {
         position: absolute;
         top: 50%;
         width: 2.5rem;
         height: 2.5rem;
         margin-top: -1.25rem;
         display: flex;
         align-items: center;
         justify-content: center;
         cursor: pointer;
         border-radius: 50%;
         left: 180px;
         background-color: $fire-engine-red !important;
         border-color: transparent;
         color: white;
         box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06),
            0px 7px 9px rgba(0, 0, 0, 0.12),
            0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;

         i {
            transition: transform $transitionDuration;
         }

         &:hover {
            background-color: rgba($fire-engine-red, 0.7);
            transition: background-color $transitionDuration;
         }
      }

      .p-link:focus {
         box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06),
            0px 7px 9px rgba(0, 0, 0, 0.12),
            0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
      }

      .header-right-section {
         display: flex;
         flex-grow: 1;
         justify-content: end;
         .label.size-small {
            @media (min-width: 450px) {
               display: none;
            }
            font-size: 10px;
            color: $header-text-color;
            padding-right: 10px;
            padding-left: 24px;
         }
         .label.size-normal {
            @media (max-width: 450px) {
               display: none;
            }
            font-size: 12px;
            color: $header-text-color;
            padding: 12px 34px;
         }
         #sign-in-button {
            font-size: $input-control-font-size;
            margin-right: 30px;
            font-family: $input-control-font-style;
            background-color: $fire-engine-red !important;
            border-color: transparent;
         }
      }
   }
}
