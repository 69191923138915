@import "../../../../../style/appStyle";
@import "./../../inputStyle";

.single-select {
  width: 100%;
  .input-label-container {
    height: 100%;
    .input-label-image-container {
      width: 100%;
      position: relative;
      .input-label-image {
        @include input-label-image;
      }
    }
    .p-dropdown {
      height: 100%;
      width: 100%;
      .p-inputtext {
        @include input-label-text;
      }
      .p-dropdown-label {
        @include dropdown-label;
      }

      .p-dropdown-trigger {
        @include dropdown-chevron-container;
      }
    }
    .pi-chevron-down {
      @include dropdown-chevron-down
    }

    .input-label-image-container {
      @include input-label-image-container;
    }

    .p-component {
      @include input-component;
    }
  }
}

.single-select.no-border {
  .p-dropdown {
    border: none;
  }
}