@import "../../../../src/app/style/appStyle";

$banner-image-height: 75px;

$top-space-locator-text: 10px;
$bottom-space-locator-text: 20px;

// $opportunity-card-height: 175px; -- Removed due to overlapping of card text on narrow screens (AEP-59)
@mixin no-margin-or-padding-sides {
   margin-left: 0px;
   margin-right: 0px;
   padding-left: 0px;
   padding-right: 0px;
}

.opportunity-search-container {
   padding: 0;

   #banner-image-row {
      #banner-image-container {
         padding-left: 0px !important;
         padding-right: 0px !important;
      }
   }

   #banner-text-row {
      #banner-text-container {
         color: $color-davy-grey;
         font-weight: bold;
         border: none;
         padding-top: $top-space-locator-text;
         padding-bottom: $bottom-space-locator-text;
         &:focus {
            outline: none !important;
         }

         // Mobile
         @media (max-width: 768px) {
            font-size: 24px !important;
         }

         // Desktop
         @media (min-width: 768px) {
            font-size: 40px !important;
         }
      }
   }

   .mobile-container-css {
      .container,
      .container-fluid {
         @include no-margin-or-padding-sides;
      }
   }

   #opportunity-list-wrapper {
      flex-grow: 1;
      min-height: 165px;
      #opportunity-list {
         flex-grow: 0;
         overflow-y: auto;
         height: 0px;
         padding-left: 2px;
         padding-right: 2px;
         margin-top: 5px;
         .opportunity-card {
            margin: 2px 8px 8px 0px;
            padding-left: 0;
            padding-right: 0;
            cursor: pointer;
            // height: $opportunity-card-height; -- Removed due to overlapping of card text on narrow screens (AEP-59)
            .opportunity-card-header {
               display: flex;
               justify-content: space-between;
               align-items: center;

               .font13 {
                  font-size: 13px !important;
               }
            }

            .card {
               height: 100%;
            }

            @media (max-width: 720px) {
               margin: 2px 8px 8px 8px;
            }
         }
      }
   }

   #opportunity-map-row {
      height: 100%;
      .col-12 {
         padding-right: 0;
         .mapBorder {
            border-radius: 0.375rem;
         }

         @media (max-width: 720px) {
            padding-left: 0;
         }
      }
   }
}

.opportunity-search-container.landing-layout {
   #opportunity-list {
      height: 0px;
   }
}

.m-0 {
   padding-left: 0;
   padding-right: 0;
}

.mobileMap {
   margin-bottom: 10px;
   min-height: 150px;
}

.pl-0 {
   padding-left: 0;
}

.map-button {
   position: absolute !important;
   left: 120px;
}

#locationIcon {
   width: 25px;
   position: absolute;
   right: 15px;
}
