#opportunity-list-wrapper {
  display: flex;
  flex-direction: column;

  #opportunity-list {
    display: flex;
    flex-grow: 1;
    align-content: flex-start;

  }
}