.modal-input-list-group {
  .title {
    font-weight: bold;
    padding-bottom: 10px;
  }

  .modal-input-list-group-item-container {
    .modal-input-list-group-item {
      font-size: 12px;
      // Checkbox and radio background color
      accent-color: black;
    }
  }
}

.modal-footer {
  justify-content: space-around !important;
  .footer-button {
    padding-top: 4px;
    font-size: 14px;
    text-decoration-line: none !important;
  }
}