@import "../../../../style/appStyle";
@import "./../inputStyle";

$filter-mobile-breakout: 1000px;

.input-wrapper {
   flex-grow: 1;
   height: $input-control-height;
   margin-bottom: 10px;
   margin-top: 5px;
   .input {
      height: 100%;
   }

   @media (max-width: $filter-mobile-breakout) {
      //Mobile
      margin-bottom: 10px;
   }

   //This is the displayed label container
   .input-label-container {
      height: 100%;
      width: 100%;
      font-family: $input-control-font-style;
      font-size: $input-control-font-size;

      img {
         margin: 5px;
      }
   }
}
