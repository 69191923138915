@import "../../style/appStyle";

$mobile-size: 767px;
$mobile-outlet-margin: 56px 0px 10px 0px;
$desktop-outlet-margin: 56px 15px 25px 15px;

// Footer
$footer-height: $standard-measurement * 3;
$footer-logo-height: $footer-height * 0.5;
$footer-logo-width: auto;
$footer-background-color: white;

// Outlet
$outlet-background-color: white;

#layout {
   height: 100%;
   display: flex;
   flex-direction: column;

   #layout-content {
      display: flex;
      flex-grow: 1;
      transition: 0.4s ease-in-out;
      flex-direction: row;
      overflow: hidden;
      #layout-navbar {
         transform: translate3d(0px, 0px, 0px);
         margin-top: 56px;
         box-shadow: 2px 0 4px -1px rgba(0, 0, 0, 0.2),
            4px 0 5px 0 rgba(0, 0, 0, 0.14), 1px 0 10px 0 rgba(0, 0, 0, 0.12);
      }

      #layout-navbar.expanded {
         width: $navbar-expanded-width;
         #NavBarComponent {
            width: $navbar-expanded-width;
         }
      }
      #layout-navbar.contracted {
         width: $navbar-contracted-width;
      }

      .hidden {
         display: none;
      }

      .overlay {
         position: fixed;
         z-index: 997;
         height: 100%;
      }

      #layout-main {
         transition: 0.4s ease-in-out;
         display: flex;
         flex-direction: column;
         flex-grow: 1;
         overflow-y: auto;
         overflow-x: hidden;
         #layout-outlet {
            display: flex;
            flex-grow: 1;
            @media (max-width: $mobile-size) {
               margin: $mobile-outlet-margin;
            }
            @media (min-width: $mobile-size) {
               margin: $desktop-outlet-margin;
            }

            form {
               width: 100%;
            }
         }
         #layout-footer {
            flex-wrap: wrap;
            min-height: $footer-height;
            width: 100%;
            align-items: center;
            background-color: $footer-background-color;
            transition: 0.4s ease-in-out;
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #dee2e6;
            padding: 0px 10px;

            @media (max-width: 720px) {
               font-size: 10px !important;
            }
            #footer-powered-by {
               span {
                  font-size: 12px !important;
                  @media (max-width: 720px) {
                     font-size: 10px !important;
                  }
               }
               img {
                  width: $footer-logo-width;
                  height: $footer-logo-height;
                  padding-left: 12px;
                  padding-right: 12px;

                  @media (max-width: 1350px) {
                     padding-left: 5px;
                     padding-right: 5px;
                     height: $footer-logo-height * 0.75;
                  }
               }
            }

            .contact-section {
               display: flex;
               margin-right: 5px;
               margin-left: 5px;
               span {
                  font-size: 12px !important;
               }
               @media (max-width: 720px) {
                  margin-left: 2px;
                  margin-right: 2px;
                  span {
                     font-size: 10px !important;
                  }
               }
               svg {
                  margin-right: 5px;
                  align-self: center;
                  height: $footer-logo-height;
                  width: $footer-logo-height;
                  @media (max-width: 1350px) {
                     height: $footer-logo-height * 0.75;
                     margin-right: 2px;
                  }
               }
            }

            #footer-contacts-desktop {
               display: flex;
               flex-direction: row;
               .contact-section {
                  display: flex;
                  margin-right: 5px;
                  margin-left: 5px;
                  svg {
                     margin-right: 5px;
                     align-self: center;
                     height: $footer-logo-height;
                     width: $footer-logo-height;

                     @media (max-width: 1350px) {
                        height: $footer-logo-height * 0.75;
                        margin-right: 2px;
                     }
                  }
               }
            }
            #footer-social {
               margin-right: 12px;
               cursor: pointer;
               @media (max-width: 1350px) {
                  margin-right: 2px;
               }
               svg {
                  width: $footer-logo-height;
                  height: $footer-logo-height;
                  margin-left: 12px;
                  margin-right: 12px;
                  @media (max-width: 1350px) {
                     margin-left: 2px;
                     margin-right: 2px;
                     width: $footer-logo-height * 0.75;
                     height: $footer-logo-height * 0.75;
                  }
               }
            }
            footer {
               transition: 0.4s ease-in-out;
            }
            .logo-css {
               padding-left: 5px;
            }
         }
      }
   }
}
