@import "../../../../../style/appStyle";
@import "./../../inputStyle";

$_dateInputs: 'input.input-css';
$_fromToLabels: '.label-from-to';
$_textElements: #{$_dateInputs}, #{$_fromToLabels};

// The distance from the side of the control to the icon
$distance-left-to-svg-icon: 7px;

// The distance from the svg to "From"
$distance-icon-to-from: 7px;

// Distance between the ":" of a date label and the date
$distance-label-to-date: 7px;

$min-date-size: 70px;
#{$_fromToLabels}.to {
  //color: green;
}
$svg-dimensions: 16px;

#{$_textElements} {
  @include input-label-text-style;
}

// Layout
.date-range-picker {
  @include input-component;
  min-width: 200px !important;
  position: relative;
  .date-range-section-css {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    //gap: 1%;
    .date-svg {
      display: flex;
      flex-flow: row;
      width: $min-date-size;
    }
    .input-group {
      display: flex;
      flex-grow:1;

      .label-date-group:first-child {
        padding-left: $distance-icon-to-from !important;
      }
      .label-date-group:nth-child(2) {
        padding-left: 0px !important;
      }

      .label-date-group {
        display: flex;
        flex-grow:1;
        align-items: center;
        .label-from-to {
          padding-left: 0px !important;
        }
      }
    }
  }
}

.date-range-picker {
  padding-left: $distance-left-to-svg-icon !important;
  padding-right: 0px !important;
  .input-css {
    width: 80px;
    border: none !important;
    padding: 0px;
    margin-left: $distance-label-to-date;
  }

  .react-datepicker-wrapper {
    display: flex;
    height: 100%;
    background-color:white;
    flex-flow: column;
    justify-content: center;

  }
  .date-range-section-css {
    height: inherit;
    .label-date-group {
      @include input-label-text;
      .label-from-to {
        font-weight: bold;
      }
    }
    .date-svg {
      width: $control-input-image-dimension;
      svg {
        display: block;
        margin:auto;
        width: $control-input-image-dimension;
        height: $control-input-image-dimension;
        fill: gray;
        background: none;
        z-index: 5;
      }
    }
  }
}

.date-range-picker {
  @media (max-width: 1200px) and (min-width: 1000px){
    .label-from-to {
      display: none !important;
    }
  }
}