@import "../../../style/appStyle";

// Component
// Label Container
// Label
// Chevron Container

//$icon-container-dimension 50px;

$hovered-input-bg-color: #f2f3f5;
// contains the icon
@mixin icon-container {
   background-color: red !important;
}

@mixin input-component {
   background: #ffffff;
   border: 1px solid #ced4da;
   transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
   transition-duration: 0.2s, 0.2s, 0.2s, 0.2s;
   transition-timing-function: ease, ease, ease, ease;
   transition-delay: 0s, 0s, 0s, 0s;
   transition-property: background-color, color, border-color, box-shadow;
   border-radius: 6px;

   &:hover {
      background-color: $hovered-input-bg-color;
   }
}

@mixin input-label-text-style {
   font-family: $input-control-font-style;
   font-size: $input-control-font-size;
   color: $input-control-font-color;
}

@mixin input-label-text {
   @include input-label-text-style;
   margin: 0;
   align-items: center;
   height: 100%;
   padding-left: 30px;
   display: flex;
}

@mixin input-label-container {
   display: flex;
   cursor: pointer;
   overflow: hidden;
   height: 100%;
   flex: 1 1 auto;
   margin: 0;
   align-items: center;
   padding: 0 0 0 15px;
}

@mixin select-dropdown-container {
   cursor: pointer;
   color: #6c757d;
   width: 3rem;

   &:hover {
      color: #343a40;
      border-color: transparent;
      background: #e9ecef;
   }
   &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #ddd6fe;
   }
}

@mixin input-label-image-container {
}

// Styling for the input image to the left of the control label
$control-input-image-dimension: $input-control-height * (1/3);
@mixin input-label-image {
   background: none;
   top: ($input-control-height - $control-input-image-dimension)/2;
   margin: auto;
   position: absolute;
   z-index: 4;
   height: $control-input-image-dimension;
   width: $control-input-image-dimension;
   margin: 0px 7px;
}

@mixin dropdown-chevron-down {
   background-image: url("../../../../assets/svgs/caret-down-fill.svg");
   &.pi-chevron-down:before {
      visibility: hidden;
   }
}

@mixin dropdown-chevron-container {
   width: 30px;
}

@mixin dropdown-label {
   padding-right: 0px;
}
