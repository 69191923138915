@import "src/app/style/appStyle";

.no-future-appointments-section {
   font-size: 14px;

   &.desktop-section-css {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
   }
   &.mobile-section-css {
      display: flex;
      flex-direction: column;
      gap: 20px;
   }
   .make-appointment-button {
      width: 250px;
      font-size: 12px;
      background-color: $fire-engine-red !important;
      border-color: $fire-engine-red !important;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
         0 4px 8px 3px rgba(60, 64, 67, 0.15);
   }
}
