@import "../../../style/appStyle";

$filter-mobile-breakout: 1000px;
$mobile-filter-border-color: $mercury-mist-color;

.filter-input-form {
   border-width: 0;
   box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
      0 4px 8px 3px rgba(60, 64, 67, 0.15);
   border-radius: 0.375rem;
   padding: 15px;
   margin-bottom: 25px;

   .filter-input-form-row {
      display: flex;

      &.stretch {
         justify-content: space-between;
         .filter-control-wrapper {
            display: flex;
            height: 100%;

            &.grow {
               flex-grow: 1;
               gap: 0.8vw;

               @media (max-width: $filter-mobile-breakout) {
                  //Mobile
                  gap: 2vw;
               }
            }
         }

         @media (min-width: $filter-mobile-breakout) {
            //Desktop
            flex-direction: row;
            justify-content: space-between;
            //border: 1px solid $mobile-filter-border-color;
            gap: 0.8vw;
         }

         @media (max-width: $filter-mobile-breakout) {
            //Mobile
            flex-direction: column;
         }
      }

      &.no-stretch {
         justify-content: flex-start;

         .filter-control-wrapper {
            margin-right: 10px;
         }
      }
   }
}

.icon-label {
   @media (max-width: $filter-mobile-breakout) {
      //Mobile
      display: none;
   }
}

@media (max-width: 720px) {
   .date-picker-container {
      /* Adjust the width for mobile devices */
      width: auto;
      align-items: flex-end;
   }
}

.custom-wrapper-mobile {
   display: flex;
   justify-content: flex-end;
   width: 100%;
   align-items: center;

   .btn-primary {
      margin: 0;
   }
}

.custom-wrapper {
   height: 50px;
   margin-bottom: 10px;
   margin-top: 5px;
}

.button-wrapper {
   display: flex;
   height: 50px;
}

.form-input-counter-wrapper {
   display: inline-block;
   width: 32%;
}
