$light-gray: #d5dae0;
$aqua-haze: #f2f3f5;
$link-water: #dfe3e8;
$lilly-white: #edf5ff;
.appointment-slot {
   width: 100%;
   height: 90px;
   background-color: white;
   display: flex;
   justify-content: space-between;
   align-items: center;

   &:hover {
      .date {
         background-color: $link-water;
      }
      .detail-section,
      .status-badge {
         background-color: $lilly-white;
      }
   }

   .date {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 100%;
      background-color: $aqua-haze;
      border-radius: 3px;
      text-align: center;
      padding: 4px;
      border: 1px solid $light-gray;
      p {
         font-weight: bold;
      }
      p.month {
         text-transform: uppercase;
         font-size: 10pt !important;
      }
      p.day {
         font-size: 16pt !important;
      }
   }
   .detail-section {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      border: 1px solid $light-gray;
      padding: 5px 10px 5px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .sub-title {
         span {
            font-size: 12px !important;
            margin-top: 4px;
         }
      }

      .left-section {
         .title {
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: 8px;

            img {
               width: 20px;
               height: auto;
            }

            span {
               font-size: 14px;
            }
         }
      }

      .right-section {
         width: 20%;
         min-width: 200px;
         display: flex;
         justify-content: space-between;
         flex-direction: row;
         .labels {
            display: flex;
            flex-direction: column;
            svg {
               margin-right: 5px;
            }
            img {
               width: 30px;
               height: auto;
               cursor: pointer;
            }
            p {
               font-size: 12px !important;
               b {
                  font-size: 13px !important;
               }
            }
         }
         .map-directions {
            display: flex;
            width: 40px;
            cursor: pointer;
            img {
               width: 35px;
               height: auto;
            }
         }
      }
   }

   .actions {
      height: 100%;
      border-radius: 3px;
      width: 340px;
      padding: 10px;
      border: 1px solid $light-gray;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      @media (max-width: 1024px) {
         flex-direction: column;
         width: 200px;
         button {
            width: 150px;
         }
      }

      &:hover {
         background-color: $lilly-white;
      }

      button {
         background-color: white;
         border-radius: 6px;
         padding: 7px 10px;
         border: 0.5px solid lightgrey;
         display: flex;
         justify-content: center;
         align-items: center;
         font-size: 10pt !important;
         color: #6c757d;
         font-weight: 500;
         box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
            0 4px 8px 3px rgba(60, 64, 67, 0.15);
         img {
            width: 24px;
            height: auto;
         }
      }
   }
   .status-badge {
      width: 200px;
      height: 100%;
      border-radius: 3px;
      border: 1px solid $light-gray;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-top: 5px solid;
      gap: 10px;

      svg {
         width: 24px;
         height: 24px;
      }

      p {
         font-weight: bold;
      }
   }
}
