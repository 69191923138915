// Colors
$light-gray: #d5dae0;
$fire-engine-red: #cd2129;
$color-nebula: hsl(212, 15%, 83%);
$color-davy-grey: #515c66;
$mercury-mist-color: #e3e7eb;

$input-control-font-style: "Roboto", "Helvetica Neue", sans-serif !important;
$input-control-font-size: 14px;
$input-control-border: 1px solid $color-nebula !important;
$input-control-height: 50px;
$input-control-font-color: #6c757d;

// Roughly eq to a square in lucid
$standard-measurement: 10px;

// Navbar
$navbar-expanded-width: 202px;
$navbar-contracted-width: 3.8rem;

// Header
$header-height: 3.5rem;
$header-text-color: white;

// Red button
.btn-danger {
   background-color: $fire-engine-red;
   border-color: $fire-engine-red;
}

.p-dropdown-panel {
   z-index: 2000 !important;
}

/* roboto-regular - latin-ext_latin */
@font-face {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 400;
   src: local("Roboto"), local("Roboto-Regular"),
      url("../../assets/fonts/roboto-v20-latin-ext_latin-regular.woff2")
         format("woff2"),
      url("../../assets/fonts/roboto-v20-latin-ext_latin-regular.woff")
         format("woff");
   /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin-ext_latin */
@font-face {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 500;
   src: local("Roboto Medium"), local("Roboto-Medium"),
      url("../../assets/fonts/roboto-v20-latin-ext_latin-500.woff2")
         format("woff2"),
      url("../../assets/fonts/roboto-v20-latin-ext_latin-500.woff")
         format("woff");
   /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin-ext_latin */
@font-face {
   font-family: "Roboto";
   font-style: normal;
   font-weight: 700;
   src: local("Roboto Bold"), local("Roboto-Bold"),
      url("../../assets/fonts/roboto-v20-latin-ext_latin-700.woff2")
         format("woff2"),
      url("../../assets/fonts/roboto-v20-latin-ext_latin-700.woff")
         format("woff");
   /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
